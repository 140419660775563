import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthProtect from '../guards/AuthGuard';
// layouts
import DashboardLayout from '../layouts/dashboard';
//
import { PATH_DASHBOARD } from './paths';

// ----------------------------------------------------------------------

const DashboardRoutes = {
  path: PATH_DASHBOARD.root,
  guard: AuthProtect,
  layout: DashboardLayout,
  routes: [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.general.home,
      component: lazy(() => import('../views/company/home/Home'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.employees,
      component: lazy(() => import('../views/company/employees/Employees'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.general.interviews,
      component: lazy(() => import('../views/company/interviews/Interviews'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.general.pageOne} />
    },
    {
      exact: true,
      path: PATH_DASHBOARD.root,
      component: () => <Redirect to={PATH_DASHBOARD.general.pageOne} />
    },

    // APP
    // ----------------------------------------------------------------------
    {
      exact: true,
      path: PATH_DASHBOARD.app.pageFour,
      component: lazy(() => import('../views/PageFour'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.app.pageFive,
      component: lazy(() => import('../views/PageFive'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.app.pageSix,
      component: lazy(() => import('../views/PageSix'))
    },
    {
      exact: true,
      path: PATH_DASHBOARD.app.root,
      component: () => <Redirect to={PATH_DASHBOARD.app.pageFour} />
    },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default DashboardRoutes;
