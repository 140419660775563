// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  page: getIcon('ic_elements'),
  employees: getIcon('ic_user'),
  interviews: getIcon('ic_charts'),
  dashboard: getIcon('ic_dashboard')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'Home',
        href: PATH_DASHBOARD.general.home,
        icon: ICONS.page
      },
      {
        title: 'Employees',
        href: PATH_DASHBOARD.general.employees,
        icon: ICONS.employees
      },
      {
        title: 'Interviews',
        href: PATH_DASHBOARD.general.interviews,
        icon: ICONS.interviews
      }
    ]
  }

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'Drop',
  //       href: PATH_DASHBOARD.app.root,
  //       icon: ICONS.dashboard,
  //       items: [
  //         {
  //           title: 'page Four',
  //           href: PATH_DASHBOARD.app.pageFour
  //         },
  //         {
  //           title: 'Page Five',
  //           href: PATH_DASHBOARD.app.pageFive
  //         },
  //         {
  //           title: 'Page Six',
  //           href: PATH_DASHBOARD.app.pageSix
  //         }
  //       ]
  //     }
  //   ]
  // }
];

export default sidebarConfig;
