import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { PATH_AUTH } from '../routes/paths';

export default function AuthProtect({ children }) {
  const { token } = useSelector((state) => state.user);

  if (!token) {
    return <Redirect to={PATH_AUTH.login} />;
  }

  return <>{children}</>;
}
